<script lang="ts" setup>
const open = ref(false);

const showThanks = ref(false);

const pending = ref(false);

const route = useRoute();

if (
  !window.localStorage.knowledgeHubSurveyDismissed
  && route.path.startsWith("/resources/knowledge-hub")
) {
  open.value = true;
}

watch(open, (newValue) => {
  if (!newValue) {
    window.localStorage.knowledgeHubSurveyDismissed = true;
  }
});

const handle = async (event: Event) => {
  pending.value = true;

  const formData = new FormData(event.target as HTMLFormElement);

  try {
    // This URL has to be anything not handled by a serverless function
    await $fetch("/site.webmanifest", {
      method: "POST",
      // @ts-expect-error this is OK as long as the form doesn't have a file input in it, see https://github.com/microsoft/TypeScript/issues/30584
      body: new URLSearchParams(formData),
    });
  } catch {
    // We don't check if the response is OK as we shouldn't block access to content if we fail to collect data
  }

  showThanks.value = true;

  setTimeout(() => (open.value = false), 500);

  pending.value = false;
};
</script>

<template>
  <LazyOtherModal
    v-model="open"
    class="flex items-center justify-center"
  >
    <div
      class="knowledge-hub-survey relative w-full max-w-[872px] rounded-sm bg-ui-grey4 text-center before:absolute before:left-0 before:top-0 before:h-2 before:w-full before:content-['']"
    >
      <div class="p-5">
        <template v-if="showThanks">
          <h3 class="mb-3">
            Thank you
          </h3>
        </template>
        <template v-else>
          <h3 class="mb-3">
            Which of the below best describes you?
          </h3>
          <p class="mb-4">
            We are looking to better understand the people who come to our
            website. We would appreciate if you could take a moment to let us
            know which of the below best describes you.
          </p>
          <form
            netlify
            @submit.prevent="handle"
          >
            <input
              type="hidden"
              name="form-name"
              value="knowledge-hub-survey"
            />
            <div class="mb-4 flex justify-center">
              <select
                name="visitortype"
                placeholder="Reason*"
                aria-label="Reason*"
                required
                class="sm:w-1/3"
              >
                <option>Institutional Investor</option>
                <option>Policy Maker</option>
                <option>Academic</option>
                <option>Journalist</option>
                <option>Prospective Employee</option>
                <option>General Public</option>
              </select>
            </div>
            <button
              class="btn btn-primary btn--icon btn--email w-[120px] justify-center text-base"
              :disabled="pending"
            >
              Submit
            </button>
          </form>
        </template>
      </div>
    </div>
  </LazyOtherModal>
</template>

<style lang="scss" scoped>
.knowledge-hub-survey::before {
  background: linear-gradient(
    to bottom,
    theme("colors.ui-grey4") 0 2px,
    rgb(0 0 0 / 60%) 2px 4px,
    theme("colors.ui-grey4") 4px 6px,
    rgb(0 0 0 / 60%) 6px 8px
  );
}
</style>
